import { CONSTANTS } from "../constants/Constants";
import { fetchAllBoards, fetchUserPreference, updateFTUserPreference } from "../api/FeatureTracker";

const { ACTIONS } = CONSTANTS;

export const setIsLoading = (data) => ({
  type: ACTIONS.SET_IS_LOADING,
  payload: { isLoading: data },
});

export const setBoards = (data) => ({
  type: ACTIONS.SET_BOARDS,
  payload: { boards: data },
});

export const setFTUserPreference = (data) => ({
  type: ACTIONS.SET_FT_USER_PREFERENCE,
  payload: { userPreference: data },
});

export const putUserPreference = data => ({
  type: ACTIONS.PUT_USER_PREFERENCE,
  payload: { updatedPreference: data }
})

export const getBoards = (baseUrl) => (dispatch) => {
  dispatch(setIsLoading(true));
  fetchAllBoards(baseUrl)
    .then(({ boards = [] }) => {
      dispatch(setBoards(boards));
    })
    .finally(() => dispatch(setIsLoading(false)));
};

export const setUserPreference = (baseUrl) => (dispatch) => {
  dispatch(setIsLoading(true));
  fetchUserPreference(baseUrl)
    .then(({ userId, userPreference = {} }) =>
      dispatch(setFTUserPreference({ ...userPreference, userId }))
    )
    .finally(() => dispatch(setIsLoading(false)));
};

export const setCurrentUserPreference = (baseUrl, { payload }) => dispatch => {
  updateFTUserPreference(baseUrl, payload).then(() => {
    dispatch(putUserPreference(payload));
  });
}