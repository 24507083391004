import { EMPTY_ARRAY, EMPTY_OBJECT } from "@tekion/tekion-base/app.constants";
import { CONSTANTS } from "../constants/Constants";
const { ACTIONS } = CONSTANTS;

const initialState = {
  boards: EMPTY_ARRAY,
  isLoading: false,
  userPreference: EMPTY_OBJECT
};

/* - Reducer for Feature Tracker.
 * @params
 *  - prevState - object
 *  - action - object
 * @return
 *  - state - object
 */
export default (prevState, action) => {
  const state = prevState || initialState;
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.SET_BOARDS:
      return {
        ...state,
        boards: payload.boards,
      };
    case ACTIONS.SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    case ACTIONS.SET_FT_USER_PREFERENCE:
      return {
        ...state,
        userPreference: payload.userPreference,
      };
    case ACTIONS.PUT_USER_PREFERENCE:
      return {
        ...state,
        userPreference: { ...state.userPreference, ...payload.updatedPreference },
      };
    default:
      return state;
  }
};
