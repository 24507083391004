// Third-party library imports.
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FeatureTracker from './FeatureTracker';

import { getBoards, setUserPreference, setCurrentUserPreference } from '../../../action/FeatureTracker.action';
import { getBoardsFromState, getIsLoadingFromState, getFTUserPreferenceFromState } from './FeatureTracker.selector';

const mapStateToProps = state => ({
  boards: getBoardsFromState(state),
  isLoading: getIsLoadingFromState(state),
  userPreference: getFTUserPreferenceFromState(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    getBoards,
    setUserPreference,
    setCurrentUserPreference,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeatureTracker);
