const { BASE_URL } = process.env;

module.exports = {
  getUserProfile: '/tapapi/v2/usermanagement/getuserprofile',
  updateUserProfile: '/tapapi/v2/usermanagement/updateuserprofile',
  getUserPreference: '/tapapi/task_management/public/v1/users/me',
  getTmUsers: '/tapapi/task_management/public/v1/users',
  getTmTranslateUsers: '/tapapi/task_management/public/v1/users/translate',
  editTmUserRole: '/tapapi/task_management/public/v1/users/permission',
  postUpdateNotification: '/tapapi/iotIncidentManagement/v1/public/updateNotification',
  postUpdateSummary: '/tapapi/task_management/public/v1/users/preference',
  getUserAvailable: '/tapapi/tap_calendar/internal/user',
  storeUserAvailable: '/tapapi/tap_calendar/internal/user/settings',
  getListing: '/tapapi/v2/common/{feature}/list',
  saveModule: '/tapapi/v2/common/tapfeature/version',
  fetchModule: '/tapapi/v2/common/{feature}/{module}/version',
  releaseVersion: '/tapapi/v2/common/releasenotes/TAP/{version}',
  getUserRole: '/tapapi/v2/usermanagement/getuserrole',
  /** Support Portal urls */
  getUserGroups: '/tapapi/userservice/v1/public/groups/filter',
  updateNotifyInUserGroups: '/tapapi/userservice/v1/public/groups/<group_id>',
  /** Cards Management Urls */
  getAllCards: '/tapapi/v2/cardmanagement/cards',
  getIndividualCard: '/tapapi/v2/cardmanagement/card/{appKey}',
  addCard: '/tapapi/v2/cardmanagement/addcard',
  deleteCard: '/tapapi/v2/cardmanagement/deletecard',
  updateCard: '/tapapi/v2/cardmanagement/updatecard',
  /** Group Management Urls */
  getAllGroups: '/tapapi/v2/groupmanagement/groups',
  getIndividualGroup: '/tapapi/v2/groupmanagement/group/{groupKey}',
  createGroup: '/tapapi/v2/groupmanagement/addGroup',
  updateGroup: '/tapapi/v2/groupmanagement/updateGroup',
  deleteGroup: '/tapapi/v2/groupmanagement/deleteGroup',
  groupTypes: '/tapapi/v2/groupmanagement/groupTypes',
  /** Tap Configurations Page urls */
  getTapConfig: '/tapapi/v2/common/tapconfigurations',
  /** User Management Urls */
  getAllUsers: '/tapapi/v2/core/user-mgmt/list',
  searchUsers: '/tapapi/v2/core/user-mgmt/search?query=',
  createUser: '/tapapi/v2/restricted/user/create',
  deleteUser: '/tapapi/v2/restricted/user/delete',
  getUserAvailability: '/tapapi/tap_calendar/internal/user/availability',
  updateUserAvailability: '/tapapi/tap_calendar/internal/user/availability/{uuid}',

  // notification settings urls
  GetUserDetails: '/tapapi/user-service/v1/public/userPreference/me',
  updateUserPreference: '/tapapi/user-service/v1/public/userPreference',
  /** Mobile Configurations */
  fetchMobileConfig: `${BASE_URL}/tapapi/v2/common/mobileconfig/all`,
  updateMobileConfig: `${BASE_URL}/tapapi/v2/common/mobileconfig/{platform}`,
  getMobileConfig: `${BASE_URL}/tapapi/v2/common/mobileconfig/get/{platform}`,

  // Feature Tracker
  getAllBoards: '/tapapi/global-feature-tracker/v1/public/boards/getAll?pageType=side_nav',
  getFTUserPreference: '/tapapi/global-feature-tracker/v1/public/users/me',
  putUserPreference: `/tapapi/global-feature-tracker/v1/public/users/userPreference`
};
