import React from 'react';
import { withRouter } from 'react-router-dom';
import Loader from '@tekion/tap-components/atoms/Loader';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import KebabMenu from 'tcomponents/molecules/KebabMenu/KebabMenu';
import Menu from 'tcomponents/molecules/Menu';
import Modal from 'tcomponents/molecules/Modal';
import FixedColumnTable from 'tcomponents/molecules/table/FixedColumnTable';
import IconAsBtn from 'tcomponents/atoms/iconAsBtn/IconAsBtn';
import withPagination from '../../../organisms/withPagination/withPagination';
import withSize from '../../../organisms/withSize';
import { getAllGroups, getWorkSpaceSize, loading } from './GroupManagement.selector';
import PageHeader from '../PageHeader/PageHeader';
import { BREAD_CRUMBS } from '../TapConfigurations/Constants'
import styles from './GroupManagement.module.scss';

import { deleteGroup, getGroups } from '../../../action/GroupManagement.action';
import {
  GROUP_MANAGEMENT_HEADER_PROPS, GROUP_MANAGEMENT_SUB_HEADER_PROPS, GROUP_MANAGEMENT_MANAGER_PROPS,
  GROUP_MANAGEMENT_DEFAULT_COLUMNS, GROUP_MANAGEMENT_COLUMN_CONFIGURATOR, GROUP_MANAGEMENT_ACTION_MENU, GROUP_MANAGEMENT_ACTIONS,
} from './constant';

const { Item } = Menu;
const TableWithPagination = withPagination(FixedColumnTable);
class GroupManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentGroup: '',
      tableColumns: [...GROUP_MANAGEMENT_DEFAULT_COLUMNS, this.getColumnConfigurator()],
    };
  }

  componentDidMount() {
    const {
      getAllGroups, apiBaseUrl,
    } = this.props;
    getAllGroups(apiBaseUrl);
  }

  addModule = () => {
    const { history } = this.props;
    history.push('/app/tapapplications/groups/create');
  };

  onGroupEdit = (groupData) => {
    const { history } = this.props;
    history.push({
      pathname: `/app/tapapplications/groups/${groupData.groupKey}/edit`,
      state: { groupData },
    });
  };

  onGroupDelete = (groupData) => {
    this.setState(
      { showDeleteModal: true, currentGroup: groupData }
    );
  };

  onCancelDelete = () => {
    this.setState({
      showDeleteModal: false,
    });
  }

  onConfirmDelete = () => {
    const { currentGroup } = this.state;
    const { deleteOldGroup, apiBaseUrl } = this.props;
    this.setState({
      showDeleteModal: false,
    });
    deleteOldGroup(apiBaseUrl, currentGroup);
  }

  renderDeleteModal = () => {
    const { showDeleteModal, currentGroup } = this.state;
    const { groupKey } = currentGroup;
    return (
      <Modal
        visible={showDeleteModal}
        title={__('Confirm delete')}
        centered
        onCancel={this.onCancelDelete}
        onSubmit={this.onConfirmDelete}
        destroyOnClose
        submitBtnText="Delete"
      >
        {__('Do you want to permanently delete {{groupKey}}?', { groupKey })}
      </Modal>
    );
  }

  renderDropDownMenu = Data => (
    <Menu className="commentActions__menu">
      <Item>
        <a onClick={() => this.onGroupEdit(Data)} tabIndex="0" role="button">
          {__('Edit')}
        </a>
      </Item>
      <Item>
        <a onClick={() => this.onGroupDelete(Data)} tabIndex="0" role="button">
          {__('Delete')}
        </a>
      </Item>
    </Menu>
  );

  onKebabItemClick = (key, original) => {
    if (key === GROUP_MANAGEMENT_ACTIONS.EDIT) {
      this.onGroupEdit(original);
    } else if (key === GROUP_MANAGEMENT_ACTIONS.DELETE) {
      this.onGroupDelete(original);
    }
  };

  getRowActionCell = ({ original }) => (
    <KebabMenu
      onClickAction={key => this.onKebabItemClick(key, original)}
      menuItems={GROUP_MANAGEMENT_ACTION_MENU}
      triggerElement={<span className="icon-overflow" />}
      rowInfo={original}
    />
  );

  getColumnConfigurator = () => ({
    ...GROUP_MANAGEMENT_COLUMN_CONFIGURATOR,
    Cell: this.getRowActionCell,
    Header: <IconAsBtn>icon-settings</IconAsBtn>,
    id: 'settings',
  });

  renderHeaderActionItems = () => (
    <Button
      view="primary"
      className={styles.addBtn}
      onClick={this.addModule}
    >
      {__('+ Add New')}
    </Button>
  );

  renderHeader = () => (
    <div className={styles.versionHeader}>
       <PageHeader 
        breadCrumbs={BREAD_CRUMBS.GROUP_MANAGEMENT}
        actionItems={this.renderHeaderActionItems()}
      />
    </div>
  )

  filterPropsMemoized = () => (
    {
      defaultFilterTypes: ['dealer_feature_status'],
      shouldHideFilterGroup: true,
      showReset: true,
      isMultiSort: true,
    }
  )

  renderPage = () => {
    const {
      allGroups, loading, tapConfig, workSpaceSize, ...rest
    } = this.props;
    const { tableColumns } = this.state;
    const { getGroups: isGetAllGroupsLoading = false } = loading;
    if (isGetAllGroupsLoading) {
      return <Loader />;
    }
    return (
      <div className={styles.container}>
        {this.renderHeader()}
        <div>
          <TableWithPagination
            data={allGroups}
            columns={tableColumns}
            headerProps={GROUP_MANAGEMENT_HEADER_PROPS}
            subHeaderProps={GROUP_MANAGEMENT_SUB_HEADER_PROPS}
            tableManagerProps={GROUP_MANAGEMENT_MANAGER_PROPS}
            isMultiSort
            useInfiniteScroll
            minRows={0}
            onAction={() => { }}
            style={{ height: workSpaceSize.contentHeight - 15, overflow: 'hidden', fontSize: '1.2rem' }}
            {...rest}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderPage()}
        {this.renderDeleteModal()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  allGroups: getAllGroups(),
  workSpaceSize: getWorkSpaceSize(),
  loading: loading(),
});

const mapDispatchToProps = (dispatch) => {
  const getAllGroups = bindActionCreators(getGroups, dispatch);
  const deleteOldGroup = bindActionCreators(deleteGroup, dispatch);
  return {
    getAllGroups,
    deleteOldGroup,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withSize(GroupManagement))
);
