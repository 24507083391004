import React from 'react';
import ErrorPage from '@tekion/tap-components/atoms/ErrorPage';
import PropTypes from 'prop-types';
import Loader from '@tekion/tap-components/atoms/Loader';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'tcomponents/molecules/Card';
import { NO_OP } from '@tekion/tap-components/constants/Constants';
import { Button } from 'antd';
import styles from './TapConfigurations.module.scss';
import { getTapConfigs, loading } from './TapConfiguration.selector';
import { getTapConfig, searchUsers } from '../../../action/TapConfiguration.action';
import PageHeader from '../PageHeader/PageHeader';
import { BREAD_CRUMBS } from '../TapConfigurations/Constants'

export class TapConfigurations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const {
      getTapConfigs, apiBaseUrl,
    } = this.props;
    getTapConfigs(apiBaseUrl);
  }

  cardOnclickHandler = (tapConfigData) => {
    const { history } = this.props;
    history.push({
      pathname: `/app/tapapplications/tapconfig/${tapConfigData.type}/edit`,
      currentModule: tapConfigData,
    });
  }

  addModule = () => {
    const { history } = this.props;
    history.push('/app/tapapplications/tapconfig/create');
  };

  renderHeader = () => (
    <div className={styles.versionHeader}>
      <PageHeader 
        breadCrumbs={BREAD_CRUMBS.TAP_CONFIGURATIONS}
      />
      <Button
        view="primary"
        className={styles.addBtn}
        onClick={this.addModule}
      >
        {__('+ Add New')}
      </Button>
    </div>
  )

  renderPage = () => {
    const { loading, tapConfig } = this.props;
    const { getTapConfig } = loading;
    if (getTapConfig || !Object.keys(tapConfig).length) {
      return <Loader />;
    }
    return (
      <div className={styles.tapConfigContainer}>
        {this.renderHeader()}

        {tapConfig.map(data => (
          <div>
            <Card
              size="small"
              className={styles.card}
              onClick={() => this.cardOnclickHandler(data)}
            >
              <div>{data.type}{__(' Configuration Details')}</div>
            </Card>

          </div>
        ))
        }
      </div>
    );
  }

  renderErrorComponent = () => <ErrorPage notFound />;

  render() {
    return (
      <React.Fragment>
        {this.renderPage()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  tapConfig: getTapConfigs(),
  loading: loading(),
});

const mapDispatchToProps = (dispatch) => {
  const getTapConfigs = bindActionCreators(getTapConfig, dispatch);
  const getSearchUsers = bindActionCreators(searchUsers, dispatch);
  return {
    getTapConfigs,
    getSearchUsers,
  };
};

TapConfigurations.propTypes = {
  history: PropTypes.object.isRequired,
  apiBaseUrl: PropTypes.string.isRequired,
  tapConfig: PropTypes.array,
  loading: PropTypes.object,
  getTapConfigs: PropTypes.func,
};

TapConfigurations.defaultProps = {
  loading: EMPTY_OBJECT,
  tapConfig: EMPTY_ARRAY,
  getTapConfigs: NO_OP,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )((TapConfigurations))
);
