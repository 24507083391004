import Http from '../services/http';
import { doGet, doPatch } from '../services/http';
import { getAllBoards, getFTUserPreference, putUserPreference } from './urls';

const FTHeaders = { oem_id: 1, source: 'Feature Tracker' };

export const fetchAllBoards = (url) => doGet(`${url}${getAllBoards}`, FTHeaders);
export const fetchUserPreference = (url) => doGet(`${url}${getFTUserPreference}`, FTHeaders);
export const updateFTUserPreference = (url, payload) => doPatch(`${url}${putUserPreference}`, payload, FTHeaders);

