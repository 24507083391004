exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".FeatureTracker_featureTracker_container__x5i3T{height:calc(100vh - 220px)}[dir] .FeatureTracker_featureTracker_container__x5i3T{padding:var(--space-20);padding:2rem;border-bottom:.1rem solid #d4d5d6}.FeatureTracker_featureTracker_container__x5i3T h3{font-weight:600}[dir] .FeatureTracker_featureTracker_container__x5i3T .FeatureTracker_featureTracker_container__status__OhFst, [dir] .FeatureTracker_featureTracker_container__x5i3T .FeatureTracker_featureTracker_container__board__3ojjp, [dir] .FeatureTracker_featureTracker_container__x5i3T .FeatureTracker_featureTracker_container__radio__2zavD{margin:var(--space-20) 0}", ""]);

// Exports
exports.locals = {
	"featureTracker_container": "FeatureTracker_featureTracker_container__x5i3T",
	"featureTracker_container__status": "FeatureTracker_featureTracker_container__status__OhFst",
	"featureTracker_container__board": "FeatureTracker_featureTracker_container__board__3ojjp",
	"featureTracker_container__radio": "FeatureTracker_featureTracker_container__radio__2zavD"
};