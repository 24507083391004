import { deepClone } from '@tekion/tap-components/utils/helper';

export const CONSTANTS = {
  ACTIONS: {
    FETCH_GET_USER_DETAILS_SUCCESS: 'FETCH_GET_USER_DETAILS_SUCCESS',
    FETCH_GET_USER_DETAILS_ERROR: 'FETCH_GET_USER_DETAILS_ERROR',
    FETCH_UPDATE_USER_DETAILS_SUCCESS: 'FETCH_UPDATE_USER_DETAILS_SUCCESS',
    FETCH_UPDATE_USER_DETAILS_ERROR: 'FETCH_UPDATE_USER_DETAILS_ERROR',
    RESET_USER_DETAIL_API_EVENT: 'RESET_USER_DETAIL_API_EVENT',
    FETCH_GET_USER_PERFERENCE_SUCCESS: 'FETCH_GET_USER_PERFERENCE_SUCCESS',
    FETCH_GET_USER_PERFERENCE_ERROR: 'FETCH_GET_USER_PERFERENCE_ERROR',
    STORE_USER_AVAILABILITY_DATA: 'STORE_USER_AVAILABILITY_DATA',
    FETCH_LISTING_SUCESS: 'FETCH_LISTING_SUCESS',
    FETCH_CARDS_SUCCESS: 'FETCH_CARDS_SUCCESS',
    FETCH_ALL_CARD_SUCCESS: 'FETCH_ALL_CARD_SUCCESS',
    FETCH_CARD_SUCCESS: 'FETCH_CARD_SUCCESS',
    FETCH_ADD_CARD_SUCCESS: 'FETCH_ADD_CARD_SUCCESS',
    FETCH_UPDATE_CARD_SUCCESS: 'FETCH_UPDATE_CARD_SUCCESS',
    FETCH_DELETE_CARD_SUCCESS: 'FETCH_DELETE_CARD_SUCCESS',
    FETCH_ALL_GROUPS_SUCCESS: 'FETCH_ALL_GROUPS_SUCCESS',
    FETCH_GROUP_SUCCESS: 'FETCH_GROUP_SUCCESS',
    FETCH_GROUP_TYPES: 'FETCH_GROUP_TYPES',
    FETCH_GROUP_TYPES_SUCCESS: 'FETCH_GROUP_TYPES_SUCCESS',
    FETCH_ADD_GROUP_SUCCESS: 'FETCH_ADD_GROUP_SUCCESS',
    FETCH_UPDATE_GROUP_SUCCESS: 'FETCH_UPDATE_GROUP_SUCCESS',
    FETCH_DELETE_GROUP_SUCCESS: 'FETCH_DELETE_GROUP_SUCCESS',
    FETCH_LISTING_ERROR: 'FETCH_LISTING_ERROR',
    FETCH_MODULE_SUCESS: 'FETCH_MODULE_SUCESS',
    FETCH_AUTHENTICATION: 'FETCH_AUTHENTICATION',
    RESET_MODULE: 'RESET_MODULE',
    LOADER: 'LOADER',
    SET_LOADER: 'SET_LOADER',
    /** Support Portal actions */
    SET_NOTIFY_SETTING_LOADING: 'GET_USER_GROUPS_LOADING',
    SET_NOTIFY_SETTING_SAVING: 'SET_NOTIFY_SETTING_SAVING',
    HYDRATE_SP_NOTIFY_SETTING: 'HYDRATE_SP_NOTIFY_SETTING',
    /** Tap Configurations Page */
    FETCH_TAP_CONFIG: 'FETCH_TAP_CONFIG',
    FETCH_TAP_USERS: 'FETCH_TAP_USERS',
    /** Mobile Configurations Page */
    SET_MOBILE_CONFIG: 'SET_MOBILE_CONFIG',
    FETCH_CREATEORUPDATE_CONFIG: 'FETCH_CREATEORUPDATE_CONFIG',
    SET_ACTIVE_MOBILE_CONFIG: 'SET_ACTIVE_MOBILE_CONFIG',
    SET_MOBILE_CONFIG_LOADING: 'SET_MOBILE_CONFIG_LOADING',
    // User management
    SET_ALL_USER: 'SET_ALL_USER',
    SET_ALL_USER_ERROR: 'SET_ALL_USER_ERROR',
    SEARCH_USER: 'SEARCH_USER',
    SET_FETCHING: 'SET_FETCHING',
    SEARCH_USER_ERROR: 'SEARCH_USER_ERROR',
    FETCH_USER_AVAILABILITY: 'FETCH_USER_AVAILABILITY',
    UPDATE_USER_AVAILABILITY: 'UPDATE_USER_AVAILABILITY',

    // Notification settings
    GET_USER_PREFERENCE_SUCCESS: 'GET_USER_PREFERENCE_SUCCESS',
    GET_USER_PREFERECE_ERROR: 'GET_USER_PREFERENCE_ERROR',

    SET_USERS: 'SET_USERS',
    SET_TRANSLATE_USERS: 'SET_TRANSLATE_USERS',
    SET_IS_FETCH_OPTIONS: 'SET_IS_FETCH_OPTIONS',

    // Feature Tracker
    SET_BOARDS: 'SET_BOARDS',
    SET_IS_LOADING: 'SET_IS_LOADING',
    SET_FT_USER_PREFERENCE: 'SET_FT_USER_PREFERENCE',
    PUT_USER_PREFERENCE: 'PUT_USER_PREFERENCE',
  },
  TAP_APPLICATIONS: [
    { name: __('Tap Chat'), icon: 'icon-message', link: 'tapchat' },
    { name: __('Release Version'), icon: 'icon-notes', link: 'releaseversion' },
    { name: __('Card Management'), icon: 'icon-card', link: 'cards' },
    { name: __('Group Management'), icon: 'icon-group-by', link: 'groups' },
    { name: __('Tap Configurations'), icon: 'icon-trend-down', link: 'tapconfig' },
    { name: __('Mobile Configurations'), icon: 'icon-mobile-phone', link: 'mobileconfigurations' },
  ],
};
export const CARD_MANAGEMENT_DEFAULT_COLUMNS = [
  {
    Header: 'Label', id: 'label', accessor: 'label', width: 250,
  },
  {
    Header: 'Short Label', id: 'slabel', accessor: 'slabel', width: 150,
  },
  {
    Header: 'Role Key', id: 'roleKey', accessor: 'roleKey', width: 350,
  },
  {
    Header: 'App Key', id: 'appKey', accessor: 'appKey', width: 250,
  },
  {
    Header: 'Icon Name', id: 'iconName', accessor: 'iconName', width: 250,
  },
  {
    Header: 'Path', id: 'path', accessor: 'path', width: 250,
  },
  {
    Header: 'Child Pages', id: 'childPages', accessor: 'childPages', width: 250,
  },
  {
    Header: 'Value', id: 'value', accessor: 'value', width: 250,
  },
  {
    Header: 'Group', id: 'groupKey', accessor: 'groupKey', width: 250,
  },
  {
    Header: 'Group Type', id: 'groupType', accessor: 'groupType', width: 250,
  },

];
export const CARD_MANAGEMENT_COLUMN_CONFIGURATOR = {
  fixed: 'right', resizable: true, width: 50,
};

export const CARD_MANAGEMENT_ACTION_MENU = [{ key: 'edit', label: __('Edit') }, { key: 'delete', label: __('Delete') }];

export const CARD_MANAGEMENT_ACTIONS = {
  EDIT: 'edit',
  DELETE: 'delete',
};
export const CARD_MANAGEMENT_TABLES_STYLES = {
  NO_BORDER: '0',
  BORDER: '2px solid #000',
  CURSOR: 'pointer',
  BACKGROUND: '#e6f3ff',
  WHITE_BACKGROUND: '#fff',
  MISSED: '#da0009',
  NOT_MISSED: '#000',
  HEIGHT: '4rem',
};

export const CARD_MANAGEMENT_TABLE_PAGE = {
  pageSize: 25,
  pageSizeOptions: [25, 50, 75],
  currentPage: 1,
};


export const INIT_COMMUNICATIONS_PAYLOAD = {
  page_info: {
    start: 0,
    rows: 25,
  },
};

// Urls
export const TRIGGERS_URL = '/app/tapapplications/cards';

export const HEADER_HEIGHT = 0;
export const SIDEBAR_WIDTH = 0;
export const FOOTER_HEIGHT = 150;

// User Management
export const USER_MANAGEMENT_DEFAULT_COLUMNS = [
  {
    Header: 'Username', id: 'name', accessor: 'name',
  },
  {
    Header: 'Email', id: 'email', accessor: 'email',
  },
  {
    Header: 'Signed Up Date', id: 'signedUpDate', accessor: 'signedUpDate',
  },
  {
    Header: 'Last Login Data', id: 'lastLoginDate', accessor: 'lastLoginDate',
  },
];

export const USER_MANAGEMENT_TABLES_STYLES = {
  NO_BORDER: '0',
  BORDER: '2px solid #000',
  CURSOR: 'pointer',
  BACKGROUND: '#e6f3ff',
  WHITE_BACKGROUND: '#fff',
  MISSED: '#da0009',
  NOT_MISSED: '#000',
  HEIGHT: '4rem',
};

export const getUsersTablePage = () => deepClone(USERS_TABLE_PAGE);

const USERS_TABLE_PAGE = {
  pageSize: 25,
  pageSizeOptions: [25, 50, 75],
  currentPage: 1,
};

export const USER_MANAGEMENT_ACTION_MENU = [{ key: 'delete', label: __('Delete') }];
export const USER_MANAGEMENT_ACTIONS = {
  DELETE: 'delete',
};

export const USER_MANAGEMENT_KEBAB_STYLES = {
  fixed: 'right',
  resizable: true,
  width: 50,
};

export const DATE_OVERIDES_TYPE = {
  available: 'available',
  holiday: 'holiday',
  unavailable: 'unavailable',
};

export const DEFAULT_TIME_FORMAT = 'HH:mm';
export const FORMATTED_TIME_FORMAT = 'hh:mm A';

export const DEFAULT_DATE_FORMAT = 'DD/MM/YY';
export const FORMATTED_DATE_FORMAT = 'MM/DD/YY';

export const REASON_COLOR_CODES = {
  holiday: '#4285f4',
  unavailable: '#ff8181',
  available: '#00bfa5',
};

export const STAGE_BASE_URL = 'https://stage-nca-tap-api.tekioncloud.xyz';
export const STAGE_BASE_URL_2 = 'https://stage-nca-tap-api-01.tekioncloud.xyz';
