export const BOARD_STATUSES = [
  {
    label: __("Open"),
    value: "Open",
  },
  {
    label: __("Backlogs"),
    value: "Backlog",
  },
  {
    label: __("In Progress"),
    value: "In-progress",
  },
  {
    label: __("All"),
    value: "All",
  },
  {
    label: __("Archived"),
    value: "Archived",
  },
  {
    label: __("Completed"),
    value: "Completed",
  },
  {
    label: __("Merged"),
    value: "Merged",
  },
];

export const VIEW_OPTIONS = [
  {
    label: __("Expand All"),
    value: false,
  },
  {
    label: __("Collapse All"),
    value: true,
  },
];
