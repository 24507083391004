import React, { useEffect, useState } from "react";

import { Select } from "antd";

import { Label } from "@tekion/tmatter";
import Radio from "tcomponents/atoms/Radio";

import { BOARD_STATUSES, VIEW_OPTIONS } from "./constants";

import styles from "./FeatureTracker.module.scss";

const { Option } = Select;

export default function FeatureTracker({
  actions,
  apiBaseUrl,
  boards,
  userPreference,
  isLoading,
}) {
  useEffect(() => {
    actions.getBoards(apiBaseUrl);
    actions.setUserPreference(apiBaseUrl);
  }, []);

  const { defaultBoardId, defaultView, isCollapsed } = userPreference;

  const isAllView = defaultBoardId === 1;

  const changeHandler = (key, value) => {
    const payload = { [key]: value };
    actions.setCurrentUserPreference(apiBaseUrl, { payload });
  };

  if (isLoading) return <p>Loading...</p>;
  return (
    <div className={styles.featureTracker_container}>
      <h3>{__("Default view")}</h3>
      <div className={styles.featureTracker_container__board}>
        <Label htmlFor="boards">{__("Boards")}</Label>
        <Select
          name="boards"
          value={defaultBoardId}
          style={{ width: 300 }}
          onChange={(value) => changeHandler("defaultBoardId", value)}
        >
          {boards.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      </div>
      <div className={styles.featureTracker_container__status}>
        <Label htmlFor="boards">{__("Boards Status")}</Label>
        <Select
          disabled={isAllView}
          name="boards"
          value={isAllView ? 'All' : defaultView}
          style={{ width: 300 }}
          onChange={(value) => changeHandler("defaultView", value)}
        >
          {BOARD_STATUSES.map(({ label, value }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </div>
      <div className={styles.featureTracker_container__radio}>
        <Radio
          disabled={isAllView}
          value={isAllView ? false : isCollapsed}
          radios={VIEW_OPTIONS}
          onChange={({ target: { value } }) => changeHandler("isCollapsed", value)}
        />
      </div>
    </div>
  );
}
